import type { TUser } from 'src/design-system'

export interface Win {
  winnerId: number
  reporterId: number
  tookPlaceOn: string
  id: number
}

export interface WinCategory {
  label: string
  id: string
}

export type User = TUser & {
  reportCount: number
  orgRole: 'admin' | 'member'
}

export type Org = {
  id: number
  slug: string
  name: string
  membershipsVisible: boolean
}

export type TFramework = {
  id: number
  name: string
  slug: string
  state: FrameworkState
}

export type TReadme = {
  id: number
  visibility: 'published' | 'unpublished'
  updatedAt: string
  lastEditor?: {
    id: number
    name: string
    fname: string
    lname: string
    avatarUrl: string
    state: 'active' | 'archived' | 'not_invited'
  } | null
}

export const states = [
  'in_progress',
  'in_review',
  'published',
  'publicly_accessible',
] as const

export type FrameworkState = (typeof states)[number]

export type VisibilityStatus = 'only_me' | 'reporting_line' | 'org'

export type WinCategories = 'Win' | 'Note'
