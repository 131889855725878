import { useModalContext } from 'src/design-system'
import { Team } from 'store/modules/teams'

export const ADD_USERS_TO_TEAM_MODAL_ID = 'ADD_USERS_TO_TEAM_MODAL_ID'

export const EVENT_KEY_NAME = '$track_add_users_to_team_modal_click'

export const useAddUsersToTeamModal = () => {
  const { openModal } = useModalContext()

  return {
    openAddUsersToTeamModal: (team: Team) =>
      openModal(ADD_USERS_TO_TEAM_MODAL_ID, { team }),
  }
}
