import * as React from 'react'
import { FrameworkState, states } from '../../types/entities'
import { Dropdown } from 'src/design-system'
import cn from 'classnames'
import { TeamStateTag } from './tag'
import { TeamStateDropdownItem } from './dropdown-item'
import { errorToast } from '../../utils/error-toast'
import { useApiClient } from '../../utils/use-api-client'
import { FrameworkService } from '../../services/framework-service'
import { successToast } from '../../utils/success-toast'
import { TeamStatePublicUrl } from './team-state-public-url'
import { TeamStatePublicBanner } from './team-state-public-banner'
import { ONBOARDING_CHECKLIST_UPDATED_KEY } from 'components/onboarding-checklist/checklist-drawer'
import { TeamStateVm } from './team-state-vm'
import { omit } from '../../utils/omit'
import { throwConfettiOnClick } from '../../utils/throw-confetti'

export type TeamStateDropdownProps = {
  state: FrameworkState
  orgName?: string
  teamSlug?: string
  frameworkId: number
  onChangeState?: (state: FrameworkState) => void
  permissions: {
    allowPublishTeam?: boolean
    allowViewSettings?: boolean
    allowAddSkills?: boolean
  }
  publicFrameworkUrl?: string | null
  orgIsPublic: boolean
  orgSlug: string
  defaultOpen?: boolean
  triggerClassName?: string
  align?: Dropdown.RootProps['align']
  showPublicEducationalBanner?: boolean
  userCanEdit?: boolean
}

export const TeamStateDropdown: React.VFC<TeamStateDropdownProps> = ({
  state: initialState,
  orgName,
  orgSlug,
  frameworkId,
  onChangeState,
  permissions,
  publicFrameworkUrl,
  orgIsPublic,
  defaultOpen,
  triggerClassName,
  align = 'right',
  showPublicEducationalBanner,
}) => {
  const [currentState, setCurrentState] = React.useState(initialState)
  const client = useApiClient()
  const service = React.useMemo(() => new FrameworkService(client), [client])

  React.useEffect(() => {
    setCurrentState(initialState)
  }, [initialState])

  const onClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
    state: FrameworkState
  ) => {
    try {
      const result = await service.changeState({ frameworkId, state })
      setCurrentState(state)
      successToast(result.notice)
      if (state === 'published') throwConfettiOnClick(e)
      document.dispatchEvent(new CustomEvent(ONBOARDING_CHECKLIST_UPDATED_KEY))
    } catch (e) {
      console.error(e)
      errorToast((e as Error).message)
    }
  }

  React.useEffect(() => {
    onChangeState?.(currentState)
  }, [onChangeState, currentState])

  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (defaultOpen) setOpen(true)
  }, [defaultOpen])

  const vm = React.useMemo(() => {
    return new TeamStateVm(orgName)
  }, [orgName])

  const { statusOptions } = vm

  const CurrentStateIcon = statusOptions[currentState].icon

  const togglePublish = React.useCallback(async () => {
    const newState =
      currentState === 'publicly_accessible'
        ? 'published'
        : 'publicly_accessible'

    await service.changeState({ frameworkId, state: newState })

    setCurrentState(newState)
    document.dispatchEvent(new CustomEvent(ONBOARDING_CHECKLIST_UPDATED_KEY))
  }, [service, frameworkId, currentState])

  return permissions.allowPublishTeam ? (
    <Dropdown.Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={setOpen}
      align={align}
    >
      <Dropdown.Trigger
        variant="styled"
        className={cn('py-1.5', triggerClassName)}
      >
        <TeamStateTag
          styled={false}
          showTooltip={true}
          title={statusOptions[currentState].title}
          variant={statusOptions[currentState].variant}
          className="flex flex-row items-center"
        >
          <CurrentStateIcon
            weight={statusOptions[currentState].iconWeight || 'bold'}
            className={cn(
              'w-3.5 h-3.5 mr-1',
              statusOptions[currentState].iconClassName
            )}
          />
          {statusOptions[currentState].title}{' '}
        </TeamStateTag>
      </Dropdown.Trigger>
      <Dropdown.Content
        sideOffset={-1}
        className="flex flex-col lg:flex-row bg-white overflow-visible"
      >
        <div className="w-96">
          <div className="flex flex-col">
            {states.map((state) => (
              <TeamStateDropdownItem
                variant={statusOptions[state].variant}
                key={state}
                active={state === currentState}
                description={statusOptions[state].description}
                icon={statusOptions[state].icon}
                iconWeight={statusOptions[state].iconWeight}
                title={statusOptions[state].title}
                onChangeState={(e) => onClick(e, state)}
              />
            ))}
          </div>
          {orgIsPublic && currentState === 'publicly_accessible' && (
            <>
              <TeamStatePublicUrl
                publicFrameworkUrl={publicFrameworkUrl}
                frameworkId={frameworkId}
              />
            </>
          )}
          {!orgIsPublic && <TeamStatePublicBanner orgSlug={orgSlug} />}
        </div>
      </Dropdown.Content>
    </Dropdown.Root>
  ) : (
    <TeamStateTag
      {...omit(statusOptions[currentState], 'icon')}
      className="p-1 gap-x-1 pr-2 text-sm leading-none"
    >
      <CurrentStateIcon
        weight="bold"
        className={cn(
          'w-3.5 h-3.5 mr-1',
          statusOptions[currentState].iconClassName
        )}
      />
      {statusOptions[currentState].title}
    </TeamStateTag>
  )
}
