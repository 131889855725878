import { Tooltip } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { ColourVariantWithTheme } from '../../design-system/types/colour-variant'

type TeamStateTagProps = {
  title: string
  variant: ColourVariantWithTheme
  className?: string
  children?: React.ReactNode
  showTooltip?: boolean
  styled?: boolean
}

export const TeamStateTag: React.VFC<TeamStateTagProps> = ({
  title,
  className,
  children,
  showTooltip = true,
  styled = true,
}) => {
  const content = `Team Status: ${title}`

  return (
    <Tooltip disabled={!showTooltip} side="bottom" content={content}>
      <div
        className={cn(
          styled &&
            'border border-gray-100 rounded px-2 py-1.5 whitespace-nowrap',
          className
        )}
      >
        <span className="sr-only">{content}</span>
        {children}
      </div>
    </Tooltip>
  )
}
