import {
  Button,
  GlobalModal,
  useModalContext,
  useModalProps,
} from 'src/design-system'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { Team } from 'store/modules/teams'
import { ADD_USERS_TO_TEAM_MODAL_ID } from './utils'
import { UserSelect } from 'components/user-select'
import { store } from 'store/index'

export const AddUsersToTeamModal = observer(() => {
  const { team } = useModalProps<{
    team?: Team
  }>(ADD_USERS_TO_TEAM_MODAL_ID)

  const { closeModal } = useModalContext()

  const onSubmit = async () => {
    if (!team) return

    await store.teams.update(team.id, { users: userIds })

    closeModal()
  }

  const [userIds, setUserIds] = React.useState<string[]>([])

  const teamUserIds = team && team.users.map((u) => u.id)

  const users =
    team && teamUserIds
      ? store.users.active.filter((u) => !teamUserIds.includes(u.id))
      : []

  return (
    <GlobalModal.Root
      id={ADD_USERS_TO_TEAM_MODAL_ID}
      title={`Move existing users to ${team?.name}`}
      className="!max-w-[500px] w-full h-auto"
    >
      <div className="py-1 pl-4 pr-2 border-0 border-b border-gray-50 border-solid flex flex-row items-center justify-between">
        <h4 className="font-bold text-sm text-gray-900">
          Move existing users to {team?.name}
        </h4>
        <GlobalModal.CloseButton className="p-2 ml-auto flex items-center rounded-sm !outline-none border-px border-solid border-transparent focus:!border-theme-60">
          <X weight="bold" className="text-gray-900 w-4 h-4" />
          <span className="sr-only">Close</span>
        </GlobalModal.CloseButton>
      </div>
      <div className="p-6">
        <UserSelect
          portalled
          className="mb-4"
          borderless={false}
          isMulti
          required
          onChange={(e) => setUserIds(e)}
          users={users}
        />
        <div className="flex justify-end gap-2">
          <Button onClick={closeModal} type="button" variant="outline">
            Cancel
          </Button>
          <Button onClick={onSubmit} type="button">
            Move users
          </Button>
        </div>
      </div>
    </GlobalModal.Root>
  )
})
