import { Banner, Link } from 'src/design-system'
import { BellRinging } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export const TeamStatePublicBanner: React.VFC<{
  orgSlug: string
}> = observer(({ orgSlug }) => {
  const setPublicPath = `/orgs/${orgSlug}/organisation-home`

  return (
    <div className="mx-3 mb-3">
      <Banner variant="warning" className="rounded-md flex flex-row">
        <BellRinging size={24} weight="bold" className="pr-2" aria-hidden />
        <p className="text-sm whitespace-nowrap">
          Your Org also needs to be public.
        </p>
        <Link className="text-sm whitepace-nowrap" href={setPublicPath}>
          Settings
        </Link>
      </Banner>
    </div>
  )
})
