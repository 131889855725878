import * as React from 'react'
import { Dropdown, Input } from 'src/design-system'
import { CopyUrlLink } from 'components/copy-url-link'

export const TeamStatePublicUrl: React.VFC<{
  publicFrameworkUrl?: string | null
  frameworkId: number
}> = ({ publicFrameworkUrl, frameworkId }) => {
  if (!publicFrameworkUrl) return null
  return (
    <div className="flex flex-row justify-between items-center py-3 gap-x-3 px-3">
      <Dropdown.Button disabled className="flex flex-row bg-white p-0">
        <Input
          value={publicFrameworkUrl}
          className="text-xs bg-gray-50 text-gray-600 p-1 px-2 text-current"
          naked
          readOnly
        />
      </Dropdown.Button>
      <CopyUrlLink
        id={frameworkId}
        type="framework"
        url={publicFrameworkUrl}
        className="p-0"
      />
    </div>
  )
}
