import * as React from 'react'
import cn from 'classnames'
import { Dropdown, Tag } from 'src/design-system'
import { Check, Icon, IconWeight } from '@phosphor-icons/react'
import { ColourVariantWithTheme } from '../../design-system/types/colour-variant'

type TeamStateDropdownItemProps = {
  active: boolean
  title: string
  description: string
  variant: ColourVariantWithTheme
  icon: Icon
  iconWeight?: IconWeight
  onChangeState: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const TeamStateDropdownItem = (props: TeamStateDropdownItemProps) => {
  const {
    active,
    variant,
    title,
    description,
    icon,
    onChangeState,
    iconWeight = 'bold',
  } = props

  const Icon = icon

  return (
    <div className="flex flex-row transition-colors justify-between hover:bg-gray-50 hover:text-gray-900 focus:bg-gray-50 focus:text-gray-900">
      <Dropdown.Button
        onClick={onChangeState}
        className={cn(
          'flex flex-row items-center w-full p-0',
          active && 'hover:cursor-not-allowed'
        )}
        disabled={active}
      >
        <div className="flex flex-row text-left items-center group p-3 justify-between bg-transparent w-full">
          <div className="flex flex-row items-center gap-x-3">
            <Tag
              variant={variant}
              className={cn('p-1.5', variant === 'paper' && 'bg-gray-100')}
            >
              <Icon className="w-3 h-3" weight={iconWeight} />
            </Tag>
            <div>
              <div className="text-sm text-gray-900">{title}</div>
              <div className="text-xs text-gray-400">{description}</div>
            </div>
          </div>
          {active && (
            <div className="ml-auto">
              <Check weight="bold" className="w-4 h-4 text-green-600" />
            </div>
          )}
        </div>
      </Dropdown.Button>
    </div>
  )
}
